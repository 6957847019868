
//................................//
// No.01 Wallet Connect Feature
//................................//
.connect-btn  {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    position: inherit;
    display: block;
    border-radius: 6px;
    padding: 12px 24px;
    border: 2px solid #1a1a1a;
    background-color: #fff;
    color: #1a1a1a;
  }

.connect-btn:hover  {
  transition: all 0.3s;
  border: 2px solid #1a1a1a;
  background-color: #1a1a1a;
  color: #fff;  
}

.connected-btn  {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  position: inherit;
  display: block;
  border-radius: 6px;
  padding: 12px 24px;
  border: 2px solid #1A1A1A;
  background-color: #FFF;
}

.status-icon-on  {
  height: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 50%;
  border: 0px solid #6e6e6e;
  margin-right: 8px;
  background-color: #2BAA8A;
}

.status-icon-off  {
  height: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 50%;
  border: 0px solid #6e6e6e;
  margin-right: 8px;
  background-color: #EE3B25;
}

.wallet-address  {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  color: #6e6e6e;
  border-radius: 12px;
  padding: 0px 12px 0px 12px;
  margin-left: 16px;
  background-color: #F2F2F2;
}


//................................//
// No.02 Mint Modal Features
//................................//

.mint_h1 {
  font-size: 55px;
  font-weight: 700;
  vertical-align: baseline;
  color: #1A1A1A;
  margin-bottom: 16px;
  margin-top: 0px;

  @media (max-width: 994px) {
    font-size: 46px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
    margin-top: 12px;
  }
  @media (max-width: 599px) {
    font-size: 22px;
  }

}

.mint_h2 {
  font-size: 32px;
  font-weight: 700;
  vertical-align: baseline;
  text-align: center;
  color: #1A1A1A;
  margin-bottom: 36px;

  @media (max-width: 994px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.mint_h3 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #1A1A1A;

  @media (max-width: 994px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.mint_h3 svg{
  position: relative;
  top: 3px;
  padding: 2px;
  height: 24px;
  width: 24px;

  @media (max-width: 994px) {
    padding: 2px;
    height: 18px;
    width: 18px;
  }
  @media (max-width: 768px) {
    padding: 2px;
    height: 16px;
    width: 16px;
  }
}

.mint_p {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 24px;
  line-height: 30px;

  @media (max-width: 994px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 599px) {
    margin-bottom: 12px;
    line-height: 24px;
  }
}

.nftamount {
  width: 100%;
  height: 100%;
  position: relative;
  display: flexbox;
  float: left;
  margin-top: 36px;
  margin-bottom: 36px;
}

.mintoptions{
  display: block;
  float: left;
  height: auto;
  width: 100%;
  position: relative;
}

.mintmodal {
  list-style: none;
  display: block;
  position: relative;
}

.selection-wrapper {
  list-style: none;
  display: block;
  float: left;
  position: relative;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;

  @media (max-width: 992px) {
    margin-top: 4px;
    margin-bottom: 8px;
  }
    @media (max-width: 768px) {
      margin-top: 0px;
      margin-bottom: 0px;
  }
}

.mint-amount {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  position: relative;
  display: block;
  margin: 4px 0px 4px 0px;
  border-radius: 6px;
  width: 100%;
  height: 80px;
  
  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 18px;
    margin: 32px 0px 4px 0px;
  }
  @media (max-width: 768px) {
    margin: 16px 0px 4px 0px;
  }
}

.mint-amount select::after  {
  box-sizing: border-box;
  position: relative;
  padding-right: 32px;
}

.mint-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #888888;
}

.mint-select::-ms-expand {
  display: none;
}

.mint-option {
  padding: 12px 0px 0px 12px;
  border-style: 2px solid #999;
  color: #555555;
  border-radius: 6px;
  letter-spacing: .05em;
  text-shadow: none;
  border: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
}

.btn-box {
  list-style: none;
  display: block;
  float: left;
  position: relative;
  width: 100%;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

}

.mint-calltoaction {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  text-align: center;
  text-decoration: none;
  letter-spacing: .025em;
  font-kerning: normal;
  border-radius: 6px;
  border: solid 2px #1A1A1A;
  color: #FFF;
  background-color: #1a1a1a;
  width: 215px;
  height: auto;
  padding: 16px 8px;
  overflow: hidden;
  position: relative;
  display: block;
  float: left;
  margin-top: 32px;
  transition: all 0.3s;

  @media (max-width: 1000px) {
    font-size: 16px;
    width: 160px;
    padding: 12px 8px;
  }
}

.mint-calltoaction:hover {
  color: #1a1a1a;
  border: solid 2px #1A1A1A;
  background-color: #fff;
}

.mintmodule-btn {
  background: #1A1A1A;
  color: #FFF;
  border: 2px solid #1A1A1A;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  letter-spacing: .05em;
  font-kerning: normal;
  border-radius: 6px;
  width: 100%;
  height: 80px;
  padding: 10px 30px;
  overflow: hidden;
  position: relative;
  display: block;
  float: right;
  transition: all 0.3s;
  @media (max-width: 1000px) {
    height: 60px;
    font-size: 18px;
    width: 100%;
    margin: 8px 0px 8px 0px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    margin: 4px 0px 4px 0px;
  }
  
}

.mintmodule-btn:hover {
  background: #FFF;
  color: #1A1A1A;
  border: 2px solid #1A1A1A;
}

.closebox svg {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.closebox {
  display: block;
  position: absolute;
  border-radius: 50%;
  color: #000;
  border: 2px solid #000;
  background-color: #FFF;
  padding: 8px;
  top: 44px;
  right: 44px;
  height: 44px;
  width: 44px;
  z-index: 100;
  transition: all ease 300ms;

  @media (max-width: 992px) {
    padding: 6px;
    height: 40px;
    width: 40px;
    top: 36px;
    right: 36px;  
  }
  @media (max-width: 768px) {
    padding: 2px;
    height: 32px;
    width: 32px;
    top: 24px;
    right: 24px;  
  }

  @media (max-width: 599px) {
    padding: 2px;
    height: 24px;
    width: 24px;
    top: 18px;
    right: 18px;  
  }

}

.closebox:hover {
  color: #FFF;
  border: 2px solid #000000;
  background-color: #000000;
}

.cancel-btn {
  background: #1A1A1A;
  color: #FFF;
  border: 2px solid #1A1A1A;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  text-transform: uppercase;
  
  text-align: center;
  text-decoration: none;
  letter-spacing: .05em;
  font-kerning: normal;
  border-radius: 6px;
  width: 49%;
  height: 80px;
  padding: 10px 30px;
  overflow: hidden;
  position: relative;
  display: block;
  float: left;
  transition: all 0.3s;
  @media (max-width: 1000px) {
    height: 60px;
    font-size: 18px;
    width: 100%;
    margin: 8px 0px 8px 0px;
  }
  @media (max-width: 768px) {
    height: 50px;
    font-size: 16px;
    margin: 4px 0px 4px 0px;
  }
}

.cancel-btn:hover {
  background: #FFF;
  color: #1A1A1A;
  border: 2px solid #1A1A1A;
}

.close-wc {
  background: transparent;
  border: none;
  display: flex;
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 4;
  right: -50px;
  top: -32px;
  @media screen and (max-width: 1199px) {
    right: 0px;
    top: -60px;
  }
  @media screen and (max-width: 575px) {
    right: -10px;
    top: -37px;
  }
  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: absolute;
    filter: invert(1);
    transition: 0.3s;
    @media screen and (max-width: 575px) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    img {
      filter: invert(0.8);
    }
  }
}


.wc-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: #fff;
  overflow: initial;
  border-radius: 4px;
  padding: 64px;
  outline: none;
  .box_inner {
    height: 100%;
    width: 100%;
    padding: 64px;
    overflow-y: auto;
  }
}

.wallet_option_wrapper{
  display: block;
  width: auto;
  height: auto;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  align-items: center;
  @media (max-width: 1555px) {
  display: inline-flex;
  }
}

.ProviderTitle {
  display: flex;
  position: relative;
  float: left;
  font-size: 1.4em;
  text-align: center;
  width: 100%;
  margin: 8px 0px 8px 0px;
  position: relative;
  justify-content: center;
  align-items: center;

   @media (max-width: 1555px) {
    display: block;
    width: fit-content;
    margin: 0px 0px 0px 18px;
    text-align: left;
    float: left;
  }
}

.ProviderInfo {
  color:#4d4d4d;  
  display: flex;
  position: relative;
  float: left;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px 0px 18px 0px;
   @media (max-width: 1555px) {
    display: block;
    width: 100%;
    margin: 18px 0px 0px 0px;
  }
}

.WalletLogo {
  width: 125px;
  height: 125px;
  display: inline-flex;
  overflow: visible;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  & img {
    width: 100%;
    height: 100%;
  }
}

.WalletLogo { @media (max-width: 1555px) {
  display: block;
  padding-bottom: 0px;
  height: 50px;
  width: 50px;
  float: right;
}}


.walletoption_1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border-radius: 6px;
  color: #000000;
  background: #ebebeb;
  height: 375px;
  width: 48%;
  border: 0px solid #FFF;
  position: relative;
  display: block;
  float: left;
  padding: 64px;
  margin: 8px 8px 8px 0px;
  transition: all 0.3s;
  @media (max-width: 1555px) {
    height: auto;
    font-size: 18px;
    width: 100%;
    padding: 32px;
    margin: 8px 0px 8px 0px;
  }
}

.walletoption_2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border-radius: 6px;
  color: #000000;
  background: #ebebeb;
  height: 375px;
  width: 48%;
  border: 0px solid #FFF;
  position: relative;
  display: block;
  float: right;
  padding: 64px;
  margin: 8px 0px 8px 8px;

  transition: all 0.3s;
  @media (max-width: 1555px) {
    height: auto;
    font-size: 18px;
    width: 100%;
    padding: 32px;
    margin: 8px 0px 8px 0px;
  }
}

.walletoption_1:hover,.walletoption_2:hover{
  background-color: #cccccc;
} 

.wcmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: #fff;
  overflow: initial;
  border-radius: 4px;
  outline: none;
}

.box_inner_wc {
  height: 100%;
  width: auto;
  display: block;
  position: relative;
  overflow-y: auto;
  padding: 80px;
  -webkit-box-shadow:0px 0px 12px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.1);

  @media (max-width: 992px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 560px;
  }
  @media (max-width: 599px) {
    width: 460px;
  }
  @media (max-width: 490px) {
    width: 360px;
  }
  @media (max-width: 420px) {
    width: 320px;
  }
}

.custom-modal-2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: #fff;
  overflow: initial;
  border-radius: 4px;
  outline: none;
}

.box_inner_2 {
  height: 100%;
  width: 968px;
  display: block;
  position: relative;
  overflow-y: auto;
  padding: 120px;
  -webkit-box-shadow:0px 0px 12px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.1);

  @media (max-width: 992px) {
    padding: 80px;
    height: 100%;
    width: 700px;
  }
  @media (max-width: 768px) {
    padding: 60px;
    width: 560px;
  }
  @media (max-width: 599px) {
    width: 460px;
  }
  @media (max-width: 490px) {
    padding: 40px;
    width: 360px;
  }
  @media (max-width: 420px) {
    width: 320px;
  }
}

.close-mint-modal {
  background: transparent;
  border: none;
  display: flex;
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 4;
  right: -50px;
  top: -32px;
  @media screen and (max-width: 1199px) {
    right: 0px;
    top: -60px;
  }
  @media screen and (max-width: 575px) {
    right: -10px;
    top: -37px;
  }
  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: absolute;
    filter: invert(1);
    transition: 0.3s;
    @media screen and (max-width: 575px) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    img {
      filter: invert(0.8);
    }
  }
}

.sk-folding-cube {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1); 
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F2F2F2;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; 
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

.mint_loader {
  position: relative;
  display: block;
}

.notification {
  font-weight: 400;
  line-height: 1.5em;
  border-radius: 4px;
  padding: 22px;
}

.web3modal-modal-lightbox { z-index: 100 !important; }