.soontop {
  position: fixed;
  top: 0;
  left: 0px;
  right: 0px;
  z-index: 10;

  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}
.soontop .inner {
  padding: 30px 30px;
  transition: all 0.3s ease;
  @media screen and (max-width: 991px) {
    padding: 25px 25px;
  }
}

.soontop .topbar_innersoon {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.logosoon {
  max-width: 120px;
  max-height: 70px;
}

.lead {
  font-weight: 800;
  font-size: 70px;
  color: #1A1A1A;
  margin-bottom: 15px;
  line-height: 1.2;
  }
  

@media screen and (min-width: 768px) and (max-width: 991px) {
  .topbarsoon:not(.animate) {
    .logosoon {
      position: relative;
      img {
        display: none;
      }
      &::after {
        content: "";
        background-image: url(../../light.png);
        width: 120px;
        height: 70px;
        position: absolute;
        left: 15px;
        top: -13px;
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
}

.soon {
  h1,
  h2 {
    font-weight: 900;
    font-size: 125px;
    line-height: 1;
    letter-spacing: 0px;
    @media screen and (max-width: 768px) {
      font-size: 80px;
    }
  }
  .hero {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .bg-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .particles {
    position: fixed;
    right: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .contentframe{
    background-color: #fff;
    padding: 10%;
    border-radius: 8px;
  }

  .content {
    p {
      color: #000;
      margin-bottom: 35px;
      margin-top: 15px;
      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
        font-size: 18px;
      }
    }
  }
}
