/*---------------------------------------------------*/
/*	08) ob PORTFOLIO
/*---------------------------------------------------*/
.list_inner.video {
    cursor: pointer;
    .overlay {
      pointer-events: all;
      cursor: pointer;
    }
  }
  .ob_tm_fullgallery {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    background-color: #f9f9fc;
    padding: 160px 0px 90px;
  }
  .ob_tm_fullgallery .portfolio_list {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    padding-top: 60px;
  }
  .ob_tm_fullgallery ul {
    margin: 0px 0px 0px -30px;
    list-style-type: none;
  }
  .ob_tm_fullgallery ul li {
    margin: 0px 0px 30px 0px;
    width: 33.3333%;
    padding-left: 30px;
    float: left;
  }
  .ob_tm_fullgallery ul li .list_inner {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    a {
      display: block;
      overflow: hidden;
    }
    img {
      width: 100%;
      transition: all 1s cubic-bezier(0, 0, 0.1, 1);
    }
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: rgba(0, 0, 0, 0.85);
      opacity: 0;
      visibility: hidden;
      border-radius: 8px;
      transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
      pointer-events: none;
    }
  
    &:hover {
      a {
        img {
          transform: scale(1.2);
        }
      }
      &:after {
        opacity: 1;
        visibility: visible;
      }
      h3 {
        color: #474747;
      }
    }
  }
  
  .css-1eqfn0t {
    border-radius: 10px !important;
  }
  .ob_tm_fullgallery .placeholder {
    min-width: 100%;
    position: relative;
    opacity: 0;
  }
  
  .ob_tm_fullgallery .overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    border-radius: 8px;
    transition: all 1s cubic-bezier(0, 0, 0.1, 1);
    pointer-events: none;
  }
  .ob_tm_fullgallery ul li .list_inner:hover .overlay {
    opacity: 1;
    visibility: visible;
  }
  .ob_tm_fullgallery .svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    opacity: 0;
    visibility: hidden;
    transition: all 1s cubic-bezier(0, 0, 0.1, 1);
    pointer-events: none;
    z-index: 5;
    &.popup {
      pointer-events: all;
      cursor: pointer !important;
    }
  }
  .ob_tm_fullgallery ul li .list_inner:hover .svg {
    opacity: 1;
    visibility: visible;
  }
  .ob_tm_fullgallery .title {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    padding: 20px 40px 35px 40px;
    text-decoration: none;
    h3 {
      transition: 0.3s;
    }
  }
  .ob_tm_fullgallery .title h3 {
    font-weight: 600;
    font-size: 22px;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    position: relative;
    top: 10px;
    transition: all 0.4s;
    &:hover {
      letter-spacing: 1px;
    }
  }
  .ob_tm_fullgallery .title span {
    font-size: 16px;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    position: relative;
    top: 10px;
    transition: all 0.4s;
  }
  .ob_tm_fullgallery ul li .list_inner:hover .title h3 {
    opacity: 1;
    visibility: visible;
    top: 0px;
  }
  .ob_tm_fullgallery ul li .list_inner:hover .title span {
    opacity: 1;
    visibility: visible;
    top: 0px;
    transition-delay: 0.05s;
  }
  
  .ob_tm_portfolio button {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    letter-spacing: .025em;
    font-kerning: normal;
    color: #1A1A1A;
    background-color: transparent;
    border: solid 2px #1A1A1A;
    border-radius: 6px;
    margin-top: 24px;
    padding: 12px 16px;
    overflow: hidden;
    position: relative;
    display: block;
    clear: both;
    float: left;
    transition: all 0.3s;
    @media (max-width: 1000px) {
      font-size: 16px;
      width: 160px;
      padding: 12px 8px;
  }
}

.ob_tm_portfolio button:hover {
  color: #FFF;
  border: solid 2px #1A1A1A;
  background-color: #1A1A1A;
}