.ToolTipText{
  font-size: 14px;
  font-weight: 400;
}


.error_page {
  h1,
  h2 {
    font-weight: 700;
    font-size: 55px;
    line-height: 1;
    letter-spacing: 0px;
    @media screen and (max-width: 768px) {
      font-size: 36px;
    }
  }
  .hero {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .particles {
    position: fixed;
    right: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .mintbox{
    margin: 0 auto;
    display: block;
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 10%;
    border-radius: 8px;
    z-index: 100;
  }

  .mintbox>.form>.input{
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
  }

  .mintbox>.form>.input{
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
  }

  .mintbutton{
    margin: auto;
    display: block;
    padding: 10px !important;
    height: 44px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
  }

  .content {
    p {
      color: #000;
      margin-bottom: 35px;
      margin-top: 15px;
      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
        font-size: 18px;
      }
    }
  }
}

.vertical-timeline * {
  box-sizing: border-box;
}
.vertical-timeline {
  width: 100%;
  display: inline-block;
  max-width: 1170px;
  margin-top: 80px;
  position: relative;
  padding: 50px 0;
}
.vertical-timeline::after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25px;
  height: 100%;
  width: 3px;
  background: #e6e6e6;
}
.vertical-timeline.vertical-timeline--one-column-right::before {
  right: 18px;
  left: unset;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline.vertical-timeline--two-columns {
      width: 100%;
  }
  .vertical-timeline.vertical-timeline--two-columns:before {
      left: 50%;
      margin-left: -2px;
  }
}
.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-element > div {
  min-height: 1px;
}
.vertical-timeline-element:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element:first-child {
  margin-top: 0;
}
.vertical-timeline-element:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
      margin: 4em 0;
  }
  .vertical-timeline-element:first-child {
      margin-top: 0;
  }
  .vertical-timeline-element:last-child {
      margin-bottom: 0;
  }
}
.vertical-timeline-element-icon {
  position: absolute;
  font-weight: 600;
  font-size: 0.85em;
  letter-spacing: -0.02em;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 3px;
  padding: 6px;
}
.vertical-timeline--one-column-right .vertical-timeline-element-icon {
  right: 0;
  left: unset;
}
.vertical-timeline-element-icon svg {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -30px;
  }
}
.vertical-timeline-element-icon {
  transform: translateZ(0);
  backface-visibility: hidden;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  -moz-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}
@-webkit-keyframes cd-bounce-1 {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
  }
  60% {
      opacity: 1;
      -webkit-transform: scale(1.2);
  }
  100% {
      -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
      opacity: 0;
      -moz-transform: scale(0.5);
  }
  60% {
      opacity: 1;
      -moz-transform: scale(1.2);
  }
  100% {
      -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      transform: scale(0.5);
  }
  60% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
  }
  100% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
  }
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 80px;
  background: #fff;
  border-radius: 8px;
  padding: 30px;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content {
  margin-right: 60px;
  margin-left: unset;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
  background: 0 0;
  box-shadow: none;
}
.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element-content h3 {
  font-size: 32px;
  font-weight: 700;
  color: #1A1A1A;
}
@media only screen and (max-width: 725px) {
  .vertical-timeline-element-content h3 {
    font-size: 24px;
  }
}
.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0px;
  word-wrap: break-word;
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: baseline;
}
@media only screen and (max-width: 725px) {
  .vertical-timeline-element-content .vertical-timeline-element-date,
  .vertical-timeline-element-content p {
    font-size: 14px;
  }
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  display: inline-block;
}
.vertical-timeline-element-content p {
  margin: 1em 0 0;
  line-height: 1.8;
}
.vertical-timeline-element-title {
  margin: 0;
}
.vertical-timeline-element-subtitle {
  margin: 0;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}
.vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 16px;
  right: unset;
  left: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-left: 7px solid #fff;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content::before {
  left: 100%;
  border-left: 7px solid #fff;
  border-right: unset;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content::before {
  display: none;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content-arrow {
  display: none;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content h2 {
      font-size: 20px;
      font-size: 1.25rem;
  }
  .vertical-timeline-element-content p {
      font-size: 16px;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
      font-size: 14px;
      font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
      margin-left: 0;
      padding: 50px;
      width: 44%;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
      top: 24px;
      left: 100%;
      transform: rotate(180deg);
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
      position: absolute;
      width: 100%;
      left: 124%;
      top: 6px;
      font-size: 16px;
      font-size: 1rem;
  }
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content,
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content {
      float: right;
  }
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow,
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
      top: 24px;
      left: auto;
      right: 100%;
      transform: rotate(0);
  }
  .vertical-timeline--one-column-right .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow,
  .vertical-timeline--one-column-right .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
      top: 24px;
      left: 100%;
      right: auto;
      transform: rotate(0);
  }
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date,
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
      left: auto;
      right: 124%;
      text-align: right;
  }
}
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in,
  .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in {
      -webkit-animation: cd-bounce-2-inverse 0.6s;
      -moz-animation: cd-bounce-2-inverse 0.6s;
      animation: cd-bounce-2-inverse 0.6s;
  }
}
@media only screen and (max-width: 1169px) {
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-2-inverse 0.6s;
      -moz-animation: cd-bounce-2-inverse 0.6s;
      animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateX(20px);
  }
  100% {
      -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
      opacity: 0;
      -moz-transform: translateX(-100px);
  }
  60% {
      opacity: 1;
      -moz-transform: translateX(20px);
  }
  100% {
      -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
      -moz-transform: translateX(-100px);
      -ms-transform: translateX(-100px);
      -o-transform: translateX(-100px);
      transform: translateX(-100px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateX(20px);
      -moz-transform: translateX(20px);
      -ms-transform: translateX(20px);
      -o-transform: translateX(20px);
      transform: translateX(20px);
  }
  100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
  }
  100% {
      -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
      opacity: 0;
      -moz-transform: translateX(100px);
  }
  60% {
      opacity: 1;
      -moz-transform: translateX(-20px);
  }
  100% {
      -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      -moz-transform: translateX(100px);
      -ms-transform: translateX(100px);
      -o-transform: translateX(100px);
      transform: translateX(100px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
      -moz-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      -o-transform: translateX(-20px);
      transform: translateX(-20px);
  }
  100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
  }
}

.navBar button>svg{
  display: flex;
  align-items: center;
  justify-content: center;
}

.navBar-button {
  position: relative;
  float: right;
  color: #FEFEFE;
  background: transparent;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  border-style: none;
  z-index: 10;
  display: block;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.menuNav {
  opacity: 0;
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  background: #f9f9fc;
  left: 0;
  height: 0px;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  z-index: 9;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.menuNav.showMenu {
  z-index: 1;
  height: calc(100% - 94px);
  opacity: 100;
  width: 100vw;
  padding: 50px 40px 50px 40px;
  top: 94px;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.navBar img{
  max-width: 120px;
  max-height: 80px;
  float: left;

}

// The main container element
.Collapsible {
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
}

//The content within the collaspable area
.Collapsible__contentInner {
  padding: 32px;
  border: 1px solid transparent;

  p {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.75em;
    vertical-align: baseline;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 4px solid #fff;
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  color: #000000;


  &:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 20px;
    top: 15px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}
#heroglow{
  background-color: #fff ;
  -webkit-box-shadow:0px 0px 200px 200px rgb(255 255 255 / 1000%);
  -moz-box-shadow: 0px 0px 200px 200px rgb(255 255 255 / 1000%);
  box-shadow: 0px 0px 200px 200px rgb(255 255 255 / 1000%);
}
.team_description {
  display: flex;
  float: left;
}
.teambox {
  display: block;
  float: left;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 32px;
  border-bottom: 1px solid #e6e6e6;
}
.teambox h3 {
  display: block;
  float: left;
  font-size: 32px;
  font-weight: 700;
}
.teambox a svg {
  color:#4d4d4d;
  display: block;
  float: right;
  padding: 4px;
  margin-top: 4px;
  height: 30px;
  width: 30px;
}
.teambox a svg:hover {
  transition: ease all 0.3s;
  color: #000;
}
.team_desc {
  display: block;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 4px;
}

.super {
  vertical-align: super;
  font-size: 12px;
  display: inline-block;
}

.partner_padding{
  display: block;
  width: 100%;
  margin-top: 32px;
  height: auto;
  float: left;
}

.ToasterContainer{
  position: absolute !important;
  display: block;
  clear: both;
  left: 0px !important;
  top: 0px !important;
  width: inherit;
  height: inherit;
  z-index: 9999;
  inset: 36px;
}
  @media only screen and (max-width: 725px) {
    .ToasterContainer{
      inset: 36px;
    }
}